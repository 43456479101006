import React from "react";
import Seo from "../components/Seo/Seo"
import PageLayout from "../layouts/PageLayout";
import TermsSection from "../sections/TermsSection";
import { graphql } from "gatsby";

const PrivacyPage = ({ data }) => {

    if (!data) return null

    const pageContent = data.prismicPrivacypage
    const page = pageContent.data || {}

    const metaTitle = page.meta_title
    const metaDescription = page.meta_description
    const metaKeywords = page.meta_keywords
    
    return (

        <PageLayout>
            <Seo
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeywords}
            />
            <TermsSection title={page.title} content={page.content} slices={page.body}/>
        </PageLayout>
    )
}

export default PrivacyPage


export const query = graphql`
query PrivacyPageQuery($id: String) {
    prismicPrivacypage(id: {eq: $id}) {
      id
      uid
      url
      lang
      data {
        title
        meta_title
        meta_keywords
        meta_description
        index_page
        flag_page
        content
        body {
          ... on PrismicPrivacypageDataBodyTermsContent {
            id
            slice_type
            slice_label
            primary {
              title
            }
            items {
              content
            }
          }
        }
      }
    }
  }
  
`