import React from "react";

const TermsHeader = ({ title, content }) => {

    return (

        <div className="layout-container mt-5 text-center">
            <h2>{title}</h2>
            <p className="mt-4">{content}</p>
        </div>
    )
}

export default TermsHeader