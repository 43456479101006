import React from "react";
import TermsHeader from "../components/TermsHeader/TermsHeader";
import { SliceZone } from "../components/SlizeZone/SliceZone";

const TermsSection = ({ title, content, slices }) => {


    return (
        <>
            <TermsHeader title={title} content={content}/>
            <SliceZone slices={slices}/>
        </>
    )
}

export default TermsSection